<template>
    <!--编辑页与详情页相同-->
    <div class="cashDetail" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <div>
                <el-form @submit.native.prevent :inline="true" size="small" label-position="right" label-width="85px">
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="交易机构">
                                <el-input v-model="form.deptName" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="交易流水号">
                                <el-input v-model="form.tradeBar" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="交易时间">
                                <el-date-picker
                                    v-model="form.tradeTime"
                                    type="datetime"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    readonly
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="状态">
                                <el-input :value="form.settleStatus | settleStatus" readonly />
                            </el-form-item>
                        </el-col>

                        <el-col :span="8">
                            <el-form-item label="收银员">
                                <el-input v-model="form.cashier" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="业务员">
                                <el-input v-model="form.salesmanName" readonly />
                            </el-form-item>
                        </el-col>

                        <el-col :span="8">
                            <el-form-item label="会员">
                                <el-input v-model="form.memberName" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="商品总额">
                                <el-input :value="form.originalPrice | money" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="优惠">
                                <el-input :value="form.totalTradeFavour | money" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="抹零">
                                <el-input :value="form.floor | money" readonly />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="实收">
                                <el-input :value="form.paid | money" readonly />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="500"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left">
                    <!--                    type='selection'复选框 -->
                </el-table-column>
                <el-table-column prop="sku.name" label="商品名称" width="180" />
                <el-table-column prop="sku.bars" label="条码" width="180">
                    <template slot-scope="scope">
                        <div v-for="b in scope.row.sku.bars" :key="b">{{ b }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="sku.specs" label="规格" width="100" />
                <el-table-column prop="sku.unit" label="单位" width="80" />
                <el-table-column prop="sku.brand" label="品牌" width="100" />
                <el-table-column label="购买数量" width="100" prop="counts" />
                <el-table-column label="赠送数量" width="100" prop="giftCounts" />
                <el-table-column label="原单价" width="100" prop="originalPrices">
                    <template slot-scope="scope">
                        {{ scope.row.originalPrices | m }}
                    </template>
                </el-table-column>
                <el-table-column label="会员单价" width="100" prop="prices">
                    <template slot-scope="scope">
                        {{ scope.row.prices | m }}
                    </template>
                </el-table-column>
                <el-table-column label="原销售金额" width="100">
                    <template slot-scope="scope">
                        <span>{{ scope.row.counts | discountMoney(scope.row.originalPrices) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="优惠金额" width="160">
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.counts | originalMoney(scope.row.originalPrices, scope.row.realMoney)
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="实际销售金额" width="160" prop="realMoney">
                    <template slot-scope="scope">
                        {{ scope.row.realMoney | m }}
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="print"
                border
                stripe
                style="width: 100%"
                :data="PayData"
                size="mini"
                :highlight-current-row="true"
                max-height="500"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />

                <el-table-column label="支付类型" width="180">
                    <template slot-scope="scope">
                        {{ meta.payTypes[scope.row.flag] }}
                    </template>
                </el-table-column>
                <el-table-column label="支付金额" width="180">
                    <template slot-scope="scope">
                        {{ scope.row.money | money }}
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>
<script>
import MoneyUtils from '../../../../js/MoneyUtils';
import UrlUtils from '../../../../js/UrlUtils';
import Decimal from 'decimal';

export default {
    name: 'CashDetail',
    props: ['form'],
    data() {
        return {
            url: {
                queryGoods: '/goods/goods/list_c',
                queryCash: '/trade/cash/info_c/',
                queryPay: '/trade/cash/pay/list?tradeCode=',
                queryPayTypes: '/finance/pay/payType/list?deptCode=',
            },
            meta: {
                types: [],
                payTypes: {},
            },
            codes: [],
            tableData: [],
            PayData: [],
        };
    },
    mounted() {
        const _this = this;
        this.handleQuery();
        const temFavour = Decimal(this.form.totalFavour)
            .add(this.form.floor)
            .add(this.form.promotionalFavour)
            .add(_this.form.memberFavour)
            .add(_this.form.peopleFavour)
            .toNumber();
        _this.form.totalTradeFavour = temFavour;
    },
    methods: {
        handleQuery: function () {
            const _this = this;
            _this.$http.get(_this.url.queryCash + _this.form.code).then((res) => {
                UrlUtils.QueryGoodsInfoByCodes(
                    _this,
                    _this.url.queryGoods,
                    { codes: res.data.data.goodsCode },
                    (data) => {
                        data.forEach((v, i) => {
                            v.prices = MoneyUtils.moneyToYuan(res.data.data.prices[i]);
                            v.counts = res.data.data.counts[i];
                            v.giftCounts = res.data.data.giftCounts[i];
                            v.originalPrices = MoneyUtils.moneyToYuan(res.data.data.originalPrices[i]);
                            v.realMoney = MoneyUtils.moneyToYuan(res.data.data.realMoney[i]);
                        });
                        this.tableData = data;
                    }
                );
            });
            UrlUtils.QueryRemote(this, this.url.queryPay + this.form.code, (res) => {
                this.PayData = res;
            });
            UrlUtils.QueryRemote(this, this.url.queryPayTypes + this.form.deptCode, (res) => {
                const a = {};
                res.forEach((v) => {
                    a[v.template.flag] = v.template.name;
                });
                this.meta.payTypes = a;
            });
        },
    },
    filters: {
        settleStatus(settleStatus) {
            if (typeof settleStatus == 'undefined') {
                return '';
            }
            if (settleStatus == '0') {
                return '待结算';
            }
            if (settleStatus == '1') {
                return '已结算';
            }
        },
        m(money) {
            return MoneyUtils.formatMoney(money);
        },
        originalMoney(counts, originalPrices, realmoney) {
            if (typeof counts == 'undefined') {
                return '';
            } else if (typeof originalPrices == 'undefined') {
                return '';
            } else if (typeof realmoney == 'undefined') {
                return '';
            } else {
                const money = Decimal(MoneyUtils.moneyToDb(originalPrices))
                    .mul(counts)
                    .sub(MoneyUtils.moneyToDb(realmoney))
                    .toNumber();
                return MoneyUtils.moneyToYuan(money);
            }
        },
        discountMoney(counts, prices) {
            if (typeof counts == 'undefined') {
                return '';
            } else if (typeof prices == 'undefined') {
                return '';
            } else {
                const money = Decimal(MoneyUtils.moneyToDb(prices)).mul(counts).toNumber();
                return MoneyUtils.moneyToYuan(money);
            }
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
